import React from 'react';
import {Box, Badge, Container, Grid, GridItem, Heading, Text} from '@chakra-ui/react';
import logo from '../assets/img/k2logo.png';
import {FormattedMessage} from "react-intl";

export const PageHeader = () => {
    return (
        // <BackgroundVideo>
        <Box className="header-container" w="100%" py={20}>
            <Container maxW="container.lg">
                <Grid templateColumns="repeat(2, 1fr)">
                    <GridItem className="header-inner-contents" rowSpan={2} colSpan={1}>
                        <Box w="100%" mb={10}>
                            <img className="m4" width="73" height={80} src={logo} alt="logo"/>
                        </Box>

                        <Badge fontSize="1em" mb={2}><FormattedMessage id="header.newBadge"/></Badge>
                        <Heading mb={5}><FormattedMessage id="header.headline"/></Heading>
                        <Text fontSize={20}><FormattedMessage id="header.subline"/></Text>
                    </GridItem>
                </Grid>
            </Container>
        </Box>
        //</BackgroundVideo>
    )
}
